import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
// import { RootState } from "../../store";
const web = "https://wgfskvtp31.execute-api.us-east-1.amazonaws.com/latest";

export interface increaseView {
  status: 'idle' | 'loading' | 'failed';
  views: number
}

const initial: increaseView = {
  status: 'idle',
  views: 0
}

export const newViewAsync = createAsyncThunk(
  'increase/view',
  async () => {
    const { data } = await axios.get(`${web}/api/views`);
    return data;
  }
)

export const newViewSlice = createSlice({
  name: 'increase',
  initialState: initial,
  reducers: {},
  
  extraReducers: (builder) => {
    builder
      .addCase(newViewAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(newViewAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.views = action.payload;
      })
      .addCase(newViewAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
})


export default newViewSlice.reducer;