import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailInputs } from "../../pages/Contact";
import { web } from "../../store";
import axios from 'axios';

export interface messageState {
  update: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: messageState = {
  update: '',
  status: 'idle'
}

export const messageAsync = createAsyncThunk(
  'message/send',
  async (input: mailInputs) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.post(`${web}/api/views/send-mail`, input, config);
    return data;
  }
)

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(messageAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(messageAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.update = action.payload;
      })
      .addCase(messageAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export default messageSlice.reducer;