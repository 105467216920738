import * as React from 'react';
import Box from '@mui/material/Box';
import { SiMongodb } from 'react-icons/si';
import Header from '../components/Header';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { skills } from '../data';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MERN from '../components/MERN';
import { SiCss3 } from 'react-icons/si';
import { BsFillBootstrapFill } from 'react-icons/bs';
import { SiMui } from 'react-icons/si';
import { SiMysql } from 'react-icons/si';
import { SiPostgresql } from 'react-icons/si';
import { SiAmazondynamodb } from 'react-icons/si';
import { FaReact } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { FaAws } from 'react-icons/fa';
import { SiHeroku } from 'react-icons/si';
import { SiVercel } from 'react-icons/si';
import { FaAngleUp } from 'react-icons/fa';

const about = {

}

const About: React.FC = () => {

  const [extraDev, setExtraDev] = React.useState<boolean>(false);
  const [extraSEO, setExtraSEO] = React.useState<boolean>(false);
  
  React.useEffect(()=>{
    window.scrollTo({top: 0, left: 0});
    AOS.init();
  },[])

  return (
    <Box className='about' sx={about} >
      <div className="body--pass"></div>
      <Header />
      <main className="about__main">
        <h1 data-aos='fade-up' className="mb4 header-d">About <span>Me</span></h1>
        <section className="main__details">
          <div className="main__details-text" data-aos='fade-up'>
            <h2 className='sp-header' data-aos='fade-up'>Fullstack and AWS Developer</h2>
            <div className='paragraph' data-aos='fade-up'>
              <img src="img/business.png" alt="" className='paragraph__order' />
              <p>
                Hey! Thank you for checking out my portfolio. 
                I am the go to software developer when its about a fully functional web application. 
                Is it an E-commerce, travel, hotel, business or custom professional website? You can always bank on my services
                to give you that sleek and good looking web app or PWA that is secure, intuitive, mobile responsive, 
                scallable, responsive, easy to navigate and fully optimized for search engines.
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <img src="img/review.png" alt="" className='paragraph__left' />
              <p className='paragraph__text'>
                I am presently four(4) years plus in the industry and still counting. I deliver to the finest of details. 
                Reviews from client has been 99% positive. This is largely due to my proactiveness and response to feedbacks.
              </p>
              <div className="paragraph__before-2 common"></div>
              <div className="paragraph__after-2 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <div className="img paragraph__order"> <MERN /> </div>
              <p>
                I am a core MERN stack developer. I build fullstack functional applications using
                Mongo Db, Express, React and Node js. <br/> I highly enjoy implementing state management with
                redux, it makes development so easy and fun as you have direct visuals of the dynamism of your app states.
                The chrome redux extension makes it fun to work with. 
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <FaReact className='img paragraph__left' style={{color: '#2dd4bf', fontSize: '12rem', width: '14rem'}}/>
              <p className='paragraph__text'>
                My core frontend development framework is React. React enable you create fragments javaScript
                components and combine them to render into your screen. It is my most used framework prior to using NextJs.
                <br/> I also write React TypeScript. This portfolio is built with React TypeScript.
              </p>
              <div className="paragraph__before-2 common"></div>
              <div className="paragraph__after-2 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <img className='img paragraph__order' style={{width: '15rem'}} src="https://www.rlogical.com/wp-content/uploads/2023/03/Rlogical-Blog-Images-thumbnail.webp" alt="" />
              <p>
                Next.Js enables you to create full-stack web applications by extending the latest React features. Next.Js has 
                so many juices that makes its use innevitable once you come across it. It boasts of features like Built-in optimizations,
                Dynamic
                HTML Streaming and React Server Components. It render web pages on the server which makes it excellent for SEO. To be candid,
                I keyed into Next.Js pruposely for this feature coupled with the fact that i can virtually use everything that I also use in React.
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <FaNodeJs className='img paragraph__left' style={{color: '#4ade80', fontSize: '12rem', width: '14rem'}}/>
              <p className='paragraph__text'>
                Node is my favorite backend tool. If I am not using it with Express.Js, I am using it with Next.js. 
                Although I do little of PHP and Python but most of the projects I have worked in, has been Node.
              </p>
              <div className="paragraph__before-2 common"></div>
              <div className="paragraph__after-2 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <SiCss3 className='img paragraph__order' style={{ fontSize: '12rem', color: '#06b6d4', width: '14rem' }} />
              <p>
                Specifically, I use SASS CSS and Tailwind CSS for styling. SASS helps me
                write fewer CSS codes. SASS nesting even makes it more difficult for selectors
                interference, thereby making styling more fun, elegant and enjoyable to write. Although, it has a disadvantage 
                which has to do with formulating css class names. It can be fun though.
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <img src="img/tailwind.png" alt="" style={{ width: '14rem' }} className='paragraph__left' />
              <p className='paragraph__text'>
                There comes Tailwind CSS, a very exceptional CSS framework. It makes 
                you feel like you are writing inline CSS but so powerful. It makes 
                development so much faster. I love using it a lot. Unlike SASS CSS, you aren't
                battling or cracking your brain about CSS class names
              </p>
              <div className="paragraph__before-2 common"></div>
              <div className="paragraph__after-2 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <div className="img paragraph__order">
                <div className="mern">
                  <BsFillBootstrapFill style={{fontSize: '10rem', color: '#6d1b7b'}}/>
                  <SiMui style={{fontSize: '10rem', }}/>
                </div>
              </div>
              <p>
                As a web developer, you should be ready to work with whatever your clients'
                wants, except there is an exceptional reason not to. My few years of experience has 
                availed me the opportunity to work with few other CSS frameworks like Bootstrap and 
                Material UI. As long as I am still in this field, its 100% sure the list will continue.
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <div className="img paragraph__left">
                <div className="mern">
                  <div className="mern__cover">
                    <SiMongodb className='mern__icon' style={{color:'green'}} />
                    <SiMysql className='mern__icon'style={{color:'#f59e0b'}} />
                  </div>
                  <div className="mern__cover">
                    <SiPostgresql className='mern__icon' style={{color: '#1d4ed8'}}/>
                    <SiAmazondynamodb className='mern__icon' style={{color: '#4ade80'}}/>
                  </div>
                </div>
              </div>
              <p className='paragraph__text'>
                My favorite database is mongoDb, a NoSQL database. Apart from being very easy to use, its sharding, replication, 
                load balancing capabilities and high availability makes it an irrestitable tool for developers. 
                Once creating my cluster from MongoDb Atlas I prefer to work with its cloud tool (MongoDb Compass). Always fun!
                <br/>
                I have also worked with SQL database like postgreSql and MySQL but I must confess, I am more adapted to the NoSQL database.
              </p>
              <div className="paragraph__before-2 common"></div>
              <div className="paragraph__after-2 common"></div>
            </div>
            <div className='paragraph' data-aos='fade-up'>
              <div className="img paragraph__order">
                <div className="mern">
                  <SiHeroku style={{fontSize: '10rem', }}/>
                  <FaAws style={{fontSize: '10rem', color: '#6d1b7b'}}/>
                  <SiVercel style={{fontSize: '10rem', }}/>
                </div>
              </div>
              <p>
                My favorite hosting platform is AWS. I am also conversant with Heroku and Vercel hosting platform.
              </p>
              <div className="paragraph__before-1 common"></div>
              <div className="paragraph__after-1 common"></div>
            </div>
            
            <a href="https://dayve-portfolio.s3.amazonaws.com/david.pdf" target="blank" rel="noopener noreferrer">
              View CV
            </a>
          </div>
        </section>
      </main>
      <section className="about__services">
        <h1 className="services__text mb4" data-aos='fade-up'><span>My</span> Services</h1>
        <div className="services" >
          <div className="services__content" data-aos='fade-up'>
            <div className="services__content-icon">
              <IntegrationInstructionsIcon />
            </div>
            <h1 className='mb2'>Website Development</h1>
            <p>
              Are you a business owner? Do you want to expand the net of your customer
              reach, thereby increasing sales revenue? Do you have an online
              representation of your business? <br />
              {
                extraDev && <span>
                  Look no further, I can help you attain those new sales target and revenue goals with
                  beautiful, easy to use and visually appealing professional website
                  with high SEO ranking.
                </span>
              }
              <button className="extra--content" onClick={() => setExtraDev(prev => !prev)} >{!extraDev ? "read more..." : <FaAngleUp style={{fontSize:"3rem"}} />}
              </button>
            </p>
            <Link to='/contact' className="btn-services" >Contact Me</Link>
          </div>
          <div className="services__content" data-aos='fade-up'>
            <div className="services__content-icon">
              <img src="/img/seo.svg" alt="" style={{width: '7rem', height:'7rem'}} />
            </div>
            <h1 className='mb2'>SEO Services</h1>
            <p>
              I can help you achive maximum organic traffic to your business.
              When it comes to key word researching to implement the best SEO campaign strategy,
              call on me, I am your best plug. <br />
              
                            {
                extraSEO && <span>
                  I take my time to implement the best onpage and
                  Technical SEO depending on your budget. In  fact, if your budget is right, I can also 
                  help you handle the offpage SEO.
                  I have good reviews on this, you can trust me.
                </span>
              }
              <button className="extra--content" onClick={() => setExtraSEO(prev => !prev)} >{!extraSEO ? "read more..." : <FaAngleUp style={{fontSize:"3rem"}} />}
              </button>
              
            </p>
            <Link to='/contact' className="btn-services" >Contact Me</Link>
          </div>
          <div className="services__content" data-aos='fade-up'>
            <div className="services__content-icon">
              <RocketLaunchIcon/>
            </div>
            <h1 className='mb2'>Cloud Deployment</h1>
            <p>
              I can help you deploy your website to scale on the AWS platform or wherever
               your prefrence.
            </p>
            <Link to='/contact' className="btn-services" >Contact Me</Link>
          </div>

        </div>
      </section>
      <section className="about__skills">
        <h1 className='header-d mb4' data-aos='fade-up'>My <span>Skills</span></h1>
        <ul className="skills">
          {
            skills.map((skill, i) => (
              <div key={skill?.skill + i}>
              {
                  i % 2 === 0 ?
                    (
                      <li data-aos='fade-right' className="skills__list" >
                        <div className='skills__list-skill'>{skill?.skill}</div>
                        <div className="skills__list-percent">
                          <div style={{ width: `${skill?.percent}%` }}></div>
                        </div>
                      </li>
                    )
                      :
                    (
                      <li data-aos='fade-left' className="skills__list" >
                        <div className='skills__list-skill'>{skill?.skill}</div>
                        <div className="skills__list-percent">
                          <div style={{ width: `${skill?.percent}%` }}></div>
                        </div>
                      </li>
                    )
                    
              }
            </div>
            ))
          }
        </ul>
      </section>
      <Footer/>
    </Box>
  )
}

export default About;