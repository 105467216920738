import Box from '@mui/material/Box';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../store';
// import { seeViewAction } from '../actions/viewsAction';
import { viewsAsync } from '../features/view/viewSlice';


const Footer: React.FC = ()=>{

  const dispatch:AppDispatch = useDispatch();

  // const seeViewReducer = useSelector((state:RootState)=>state.seeViewReducer);
  // const {viewed} = seeViewReducer;

  const { views } = useSelector((state: RootState) => state.viewSlice);

  React.useEffect(()=>{
    // dispatch(seeViewAction());
    dispatch(viewsAsync())
  }, [dispatch])

  return (
    <Box className='footer'>
      <p className='footer__copy'>&copy; {(new Date()).getFullYear()} Okafor U. David</p>
      <p className="footer__stats">Site stats:{' '} {views && views} views</p>
    </Box>
  )
}

export default Footer;