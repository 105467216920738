import { createTheme } from '@mui/material/styles';
// import { pink, purple } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0',
      // main: purple[500],
    },
    secondary: {
      main: '#f50057',
      // main: pink[500],
    },
  },
});
