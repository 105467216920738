import { SiMongodb } from 'react-icons/si';
import { SiExpress } from 'react-icons/si';
import { FaNodeJs } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';

const MERN = () => {
  return (
    <>
      <div className="mern">
        <div className="mern__cover">
          <SiMongodb className='mern__icon' style={{color:'green'}} />
          <SiExpress className='mern__icon'/>
        </div>
        <div className="mern__cover">
          <FaReact className='mern__icon' style={{color: '#6d1b7b'}}/>
          <FaNodeJs className='mern__icon' style={{color: '#4ade80'}}/>
        </div>
      </div>
    </>
  )
}

export default MERN