import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Person4Icon from '@mui/icons-material/Person4';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
// import { sendMailAction } from '../actions/viewsAction';
import Progress from '../components/Progress';
import SnackBar from '../components/Snackbar';
import { SEND_MAIL_RESET } from '../constants/viewsConstants';
import { theme } from '../components/Theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { messageAsync } from '../features/message/messageSlice';

export interface mailInputs {
  name: string
  email: string
  subject: string
  message: string
}

const contact = {
  btn: {
    bgcolor: theme.palette.primary.main,
    transition: 'all .3s',
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    marginLeft: 'auto',
    marginRight: 'auto',
    p: '1rem 3rem',
    '&:hover':{
      bgcolor: theme.palette.primary.dark
    }
  },
  btnDisable: {
    bgcolor: theme.palette.primary.light,
    color: '#101d2c',
    opacity: '0.5',
    transition: 'all .3s',
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    marginLeft: 'auto',
    marginRight: 'auto',
    p: '1rem 3rem',
    '&:hover': {
      bgcolor: theme.palette.primary.light,
      color: '#101d2c',
      opacity: '0.5',
    }
  }
}

const Contact: React.FC = () =>{

  const dispatch: AppDispatch = useDispatch();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [popup, setPopup] = React.useState(false);

  const {status, update} = useSelector((state: RootState)=>state.sendMessage);
  // const { loading, success, error } = sendMailReducer;
  
  // const sendMailReducer = useSelector((state: RootState)=>state.sendMailReducer);
  // const {loading, success, error} = sendMailReducer;

  const handleSubmit:React.FormEventHandler = (e:React.FormEvent)=>{
    e.preventDefault();
    // console.log({name, email, subject, message});

    if (!name || !email || !subject || !message){
      alert('Please fill all fields!')
    } else{
      dispatch(messageAsync({name, email, subject, message}));
      // dispatch(sendMailAction({name, email, subject, message}));
    }
  }

  const handleDisable= ():boolean => {
    if (!name || !email || !message || !subject) return true;

    return false
  }

  // console.log(handleDisable());

  React.useEffect(()=>{

    if (update){
      setPopup(true);
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      dispatch({type: SEND_MAIL_RESET})
    }

    window.scrollTo({ top: 0, left: 0 });
    AOS.init();
  },[dispatch, update])

  return (
    <Box className='contact' sx={contact} >
      {status==='loading' && <Progress/>}
      {popup && <SnackBar severity='success' message='successful' />}
      {status==='failed' && <SnackBar severity='error' message = 'Error occured' />}
      <div className="body--pass"></div>
      <div className="top"></div>
      <Header/>
      <div className="contact__text" data-aos='fade-up'>
        <h1 className="header-d">Contact {' '} <span>Me</span> </h1>
        <p className='header-small'><span>Get</span>{' '} in touch</p>
      </div>
      <div className="contact__main">
        <Box className="contact__main-form" onSubmit={handleSubmit} component={'form'} >
          <h2 className='sp-header' data-aos='fade-up'>Message</h2>
          <div className='user-inputs mb2' data-aos='fade-up'>
            <input type="text" name="name" id="name" placeholder='name' 
              className='dark-input' value={name} 
              onChange={(e)=>setName(e.target.value)}
            />
            <input type="email" name="email" id="email" placeholder='email' 
              className='dark-input' value={email} onChange={e=>setEmail(e.target.value)}
            />
          </div>
          <input type="text" name="subject" id="subject" className='mb2 dark-input'
            placeholder='subject' value={subject} onChange={e=>setSubject(e.target.value)}
          />
          <textarea name="message" id="" cols={30} rows={10} className='dark-input mb2'
            placeholder='Enter message' value={message} onChange={e=>setMessage(e.target.value)}
          >
          </textarea>
          <Button type='submit' variant='contained'
            sx={handleDisable()? contact.btnDisable : contact.btn}
            // disabled={handleDisable()}
          >
            Submit
          </Button>
        </Box>
        <div className="contact__main-info" >
          <h2 className='sp-header' data-aos='fade-up'>Contact Info</h2>
          <div className="infos" data-aos='fade-up'>
            <div className="infos__logo">
              <Person4Icon/>
              <div className="infos__details">
                <h5>Name</h5>
                <p>Okafor U. David</p>
              </div>
            </div>
            <div className="infos__logo">
              <LocationOnIcon/>
              <div className="infos__details">
                <h5>Location </h5>
                <p>Ibadan, Oyo State, Nigeria</p>
              </div>
            </div>
            <div className="infos__logo">
              <AlternateEmailIcon/>
              <div className="infos__details">
                <h5>Email</h5>
                <p>dayvyno@gmail.com, dayvynomern2021@gmail.com</p>
              </div>
            </div>
            <div className="infos__logo">
              <PhoneIcon/>
              <div className="infos__details">
                <h5>Mobile</h5>
                <p>+234(0)70 69230276</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </Box>
  )
}

export default Contact;