import * as React from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactTypical } from '@deadcoder0904/react-typical'
import NearMeIcon from '@mui/icons-material/NearMe';
import { useDispatch} from 'react-redux';
import { AppDispatch} from '../store';
import { newViewAsync } from '../features/view/increaseViewSlice';

const home = {

}

const Homepage:React.FC = ()=>{

  const dispatch: AppDispatch = useDispatch();

  React.useEffect(()=>{
    window.scrollTo({top: 0, left: 0});

    dispatch(newViewAsync());

  },[dispatch])


  return (
    <Box sx={home} className='homepage'>
      <div className="body--pass"></div>
      <Header />
      <ul className="homepage__social">
        <li className='social__list'>
          <a href="https://linkedin.com/in/david-okafor-58b947a3" target="blank" rel="noreferrer">
            <LinkedInIcon/>
          </a>
        </li>

        <li className='social__list'>
          <a href="https://github.com/Dayvyno2021" target="blank" rel="noreferrer">
            <GitHubIcon/>
          </a>
        </li>

        <li className='social__list'>
          <a href="https://www.instagram.com/dayvyno" target="blank" rel="noreferrer">
            <InstagramIcon/>
          </a>
        </li>
      </ul>

      <main className="homepage__main">
        <h1 className="main__name"><span>Okafor{' '} </span>U. David</h1>
        <div className="main__typical">
          <span>I'm a</span>
          <ReactTypical
            steps={[
              'Cloud Engineer (AWS)', 2000, 
              'Frontend Developer', 2000,
              'Backend Developer', 2000,
              'Fullstack Developer', 2000
          ]}
            loop={Infinity}
            wrapper="p"
          />
        </div>
        <div className="main__send">
          <a href="mailto:dayvynomern2021@gmail.com"> 
          <NearMeIcon/> 
          </a>
        </div>
      </main>
      
      <div className="homepage__dave">
        <div className="dave__image"></div>
      </div>
    </Box>
  )
}

export default Homepage;