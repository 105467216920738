import * as React from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header';
import { portfolios } from '../data';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Portfolio: React.FC = () =>{

  React.useEffect(()=>{
    window.scrollTo({ top: 0, left: 0 });
    AOS.init();
  },[])


  return (
    <Box className="portfolio">
      <div className="body--pass"></div>
      <div className="top"></div>
      <Header />
      <div className="portfolio__projects">
        <div className="projects__heading" data-aos='fade-up'>
          <h1 className="header-d mb1"><span>My</span>{' '}Portfolio</h1>
          <p className='header-small'>Works<span>{' '}/{' '} projects</span></p>
        </div>
        <div className="projects__container">
          {
          portfolios.map((portfolio, i)=>(
            <div className="projects__content" key={portfolio?.name + i}>
              <div className="projects__content-image" data-aos='fade-right'>
                <img src={portfolio?.image} alt="" className="projects__content-image"/>
              </div>
              <div className="projects__content-details" data-aos='fade-left'>
                <h2>{portfolio?.name} </h2>
                <p>{portfolio?.details} </p>
                {
                  portfolio?.url ? (
                    <a href={portfolio?.url} target="blank" rel="noopener noreferrer">
                      View Project
                    </a>
                    
                  ) : (
                      portfolio?.views && (
                        <>
                          <a href={portfolio?.views?.mobile} target="blank" rel="noopener noreferrer" style={{width: '80%', textAlign: 'center', marginBottom: '4px'}}>
                            View Website (Mobile Responsive)
                          </a> 
                          <a href={portfolio?.views?.desktop} target="blank" rel="noopener noreferrer" style={{width: '80%', textAlign: 'center'}}>
                            View Dashboard (Desktop only)
                          </a> 
                        </>

                      )
                  )
                }
              </div>
            </div>
          ))
          }
        </div>
      </div>
      <Footer/>
    </Box>
  )
}

export default Portfolio;