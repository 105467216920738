import Box from '@mui/material/Box';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

const Header:React.FC = () =>{
  const [modal, setModal] = React.useState(false);


  const openModal = ():void =>{
    setModal(true);
  }

  const closeModal = (): void=>{
    setModal(false)
  }


  return (
    <Box className='header__container'  >
      <header className='header' >
        <Link to='/'>
          <img src="/img/dayve-logo.png" alt="" className='header__logo'/>
        </Link>
        <div className="header__nav" onClick={openModal}>
          <span className="header__nav-1 span-1"></span>
          <span className="header__nav-2 span-2"></span>
          <span className="header__nav-3 span-3"></span>
        </div>
      </header>

      <Drawer anchor='top' open={modal}>
       <Box className="modal__container"
          role="presentation"
       >
          <header className='modal__header'>
            <Link to='/' onClick={closeModal}>
              <img src="/img/dayve-logo.png" alt="" className='modal__header-logo' />
            </Link>
            <div className="modal__header-nav" onClick={closeModal}>
              <span className="modal__header-nav-span-1"></span>
              <span className="modal__header-nav-span-2"></span>
              <span className="modal__header-nav-span-3"></span>
            </div>
          </header>
          <nav className="modal__content">
            <ul className="modal__content--list">
              <li onClick={closeModal} onKeyDown={closeModal}>
                <Link to='/' onClick={closeModal}>Home</Link>
              </li>
              <li onClick={closeModal} onKeyDown={closeModal}>
                <Link to='/about' onClick={closeModal}>About</Link>
              </li>
              <li onClick={closeModal} onKeyDown={closeModal}>
                <Link to='/portfolio' onClick={closeModal}>Portfolio</Link>
              </li>
              <li onClick={closeModal} onKeyDown={closeModal}>
                <Link to='/contact' onClick={closeModal}>Contact</Link>
              </li>
            </ul>

          </nav>
        </Box>
      </Drawer>

    </Box>
  )
}

export default Header;