export const  GET_VIEWS_REQUEST='GET_VIEWS_REQUEST';
export const  GET_VIEWS_SUCCESS='GET_VIEWS_SUCCESS';
export const  GET_VIEWS_FAIL='GET_VIEWS_FAIL';
export const  SEE_VIEWS_REQUEST='SEE_VIEWS_REQUEST';
export const  SEE_VIEWS_SUCCESS='SEE_VIEWS_SUCCESS';
export const  SEE_VIEWS_FAIL='SEE_VIEWS_FAIL';


export const  SEND_MAIL_REQUEST='SEND_MAIL_REQUEST';
export const  SEND_MAIL_SUCCESS='SEND_MAIL_SUCCESS';
export const  SEND_MAIL_FAIL='SEND_MAIL_FAIL';
export const  SEND_MAIL_RESET='SEND_MAIL_RESET';