import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
// import {/* getViewsReducer, seeViewReducer,*/ sendMailReducer } from './reducers/viewsReducer';
import viewSlice from './features/view/viewSlice';
import newViewSlice from './features/view/increaseViewSlice';
import messageSlice from './features/message/messageSlice';

export const web = "https://wgfskvtp31.execute-api.us-east-1.amazonaws.com/latest"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    // getViewsReducer: getViewsReducer,
    // seeViewReducer: seeViewReducer,
    // sendMailReducer: sendMailReducer,
    viewSlice,
    newView: newViewSlice,
    sendMessage: messageSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
