import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';


const web = "https://wgfskvtp31.execute-api.us-east-1.amazonaws.com/latest"

export interface viewState {
  views: number;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: viewState = {
  views: 0,
  status: 'idle'
}

export const viewsAsync = createAsyncThunk(
  'see/View',
  async () => {
    const { data } = await axios.get(`${web}/api/views/see`);
    return data;
  }
)

export const viewSlice = createSlice({
  name: 'see/View',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(viewsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.views = action.payload;
      })
      .addCase(viewsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
})


export default viewSlice.reducer;